export const TEST = 'test';

export enum CardStatus {
	ACTIVATE = 'activate',
	LOST_REPLACE = 'lost_replace',
	DAMAGE_REPLACE = 'damage_replace',
	CANCEL = 'cancel'
}

export enum IconColor {
	error = '#AA232C',
	success = '#02880F'
}

export enum ExpenseType {
	NONE = 'none',
	REFUND = 'refund',
	REFUNDED = 'refunded',
	REIMBURSE = 'reimburse',
	REIMBURSED = 'reimbursed',
	FIX = 'fix',
	FIX_ASSERTED = 'fix_asserted'
}

export enum ExpenseMode {
	MANUAL = 'manual',
	TRANSACTION = 'transaction'
}

export enum AccountType {
	HSA = 'hsa',
	EVERYDAY = 'everyday',
	INVESTMENT = 'investment',
	FSA = 'fsa',
	LFSA = 'lfsa',
	DCFSA = 'dcfsa'
}

export enum AccountStatus {
	ACTIVE = 'active',
	SUSPENDED = 'suspended',
	PENDING = 'pending',
	TERMINATED = 'terminated',
	INACTIVE = 'inactive'
}

export enum AddCardErrorStatus {
	AGE_ERROR = 'AGE_ERROR',
	DUPLICATE_USER_ERROR = 'DUPLICATE_USER_ERROR',
	MAX_CARD_ERROR = 'MAX_CARD_ERROR',
	SOCURE_ERROR = 'SOCURE_ERROR'
}
export const ADD_CARD_ERROR_MSG: any = {
	[AddCardErrorStatus.AGE_ERROR]: 'Cardholders must be 18 years of age or older to receive a card.',
	[AddCardErrorStatus.MAX_CARD_ERROR]:
		'The maximum number of cardholders per account is 5. You will need to remove a card before adding a new one.',
	[AddCardErrorStatus.DUPLICATE_USER_ERROR]:
		'This user already has a card. If another card is needed, report the card lost and we’ll send you a replacement.',
	[AddCardErrorStatus.SOCURE_ERROR]: 'We are unable to create a new card for this person.'
};
export const HSA_INVESTMENT_URL = 'https://account.hsainvestments.com/sso?sessId=';

export const stateOptions = [
	{ label: 'Alabama', value: 'AL' },
	{ label: 'Alaska', value: 'AK' },
	{ label: 'Arizona', value: 'AZ' },
	{ label: 'Arkansas', value: 'AR' },
	{ label: 'California', value: 'CA' },
	{ label: 'Colorado', value: 'CO' },
	{ label: 'Connecticut', value: 'CT' },
	{ label: 'Delaware', value: 'DE' },
	{ label: 'District of Columbia', value: 'DC' },
	{ label: 'Florida', value: 'FL' },
	{ label: 'Georgia', value: 'GA' },
	{ label: 'Hawaii', value: 'HI' },
	{ label: 'Idaho', value: 'ID' },
	{ label: 'Illinois', value: 'IL' },
	{ label: 'Indiana', value: 'IN' },
	{ label: 'Iowa', value: 'IA' },
	{ label: 'Kansas', value: 'KS' },
	{ label: 'Kentucky', value: 'KY' },
	{ label: 'Louisiana', value: 'LA' },
	{ label: 'Maine', value: 'ME' },
	{ label: 'Maryland', value: 'MD' },
	{ label: 'Massachusetts', value: 'MA' },
	{ label: 'Michigan', value: 'MI' },
	{ label: 'Minnesota', value: 'MN' },
	{ label: 'Mississippi', value: 'MS' },
	{ label: 'Missouri', value: 'MO' },
	{ label: 'Montana', value: 'MT' },
	{ label: 'Nebraska', value: 'NE' },
	{ label: 'Nevada', value: 'NV' },
	{ label: 'New Hampshire', value: 'NH' },
	{ label: 'New Jersey', value: 'NJ' },
	{ label: 'New Mexico', value: 'NM' },
	{ label: 'New York', value: 'NY' },
	{ label: 'North Carolina', value: 'NC' },
	{ label: 'North Dakota', value: 'ND' },
	{ label: 'Ohio', value: 'OH' },
	{ label: 'Oklahoma', value: 'OK' },
	{ label: 'Oregon', value: 'OR' },
	{ label: 'Pennsylvania', value: 'PA' },
	{ label: 'Rhode Island', value: 'RI' },
	{ label: 'South Carolina', value: 'SC' },
	{ label: 'South Dakota', value: 'SD' },
	{ label: 'Tennessee', value: 'TN' },
	{ label: 'Texas', value: 'TX' },
	{ label: 'Utah', value: 'UT' },
	{ label: 'Vermont', value: 'VT' },
	{ label: 'Virginia', value: 'VA' },
	{ label: 'Washington', value: 'WA' },
	{ label: 'West Virginia', value: 'WV' },
	{ label: 'Wisconsin', value: 'WI' },
	{ label: 'Wyoming', value: 'WY' }
];

export const designationOptions = [
	{ label: 'Primary', value: 'primary' },
	{ label: 'Contingent', value: 'contingent' }
];

export const relationshipOptions = [
	{ label: 'Child', value: 'child' },
	{ label: 'Spouse', value: 'spouse' },
	{ label: 'Parent', value: 'parent' },
	{ label: 'Sibling', value: 'sibling' },
	{ label: 'Life partner', value: 'life partner' },
	{ label: 'Other', value: 'other' }
];

export const expenseTypeOptions = [
	{
		value: 'Dental',
		label: `Dental`
	}
];

export enum Relationships {
	CHILD = 'child',
	SPOUSE = 'spouse',
	PARENT = 'parent',
	SIBLING = 'sibling',
	LIFE_PARTNER = 'life partner',
	OTHER = 'other'
}

export enum DesignationType {
	CONTINGENT = 'contingent',
	PRIMARY = 'primary'
}
export const getSelectStyle = (styles: any) => ({
	...styles,
	borderColor: '#4e5154',
	borderRadius: '8px',
	padding: '0px 4px',
	':focus': {
		borderColor: '#4e5154'
	},
	':hover': {
		borderColor: '#4e5154'
	}
});

export enum ReimburseTo {
	EXTERNAL = 'EXTERNAL',
	EVERYDAY = 'EVERYDAY',
	CHECK = 'CHECK'
}
export enum EvaluationType {
	DEFAULT = 'DEFAULT',
	HSA_ONLY = 'HSA_ONLY',
	OPT_OUT_HSA = 'OPT_OUT_HSA'
}

export enum ExpenseGroupType {
	TAKE_ACTION = 'take_action',
	SETTLED = 'settled',
	CANCELLED = 'cancelled',
	DENIED = 'denied',
	AWAITING_REVIEW = 'awaiting_review',
	REIMBURSE = 'reimburse',
	TRAVEL_POTENTIAL = 'travel_potential'
}
export const ExpenseGroupTypeOptions = [
	{ label: 'Take Action', value: ExpenseGroupType.TAKE_ACTION },
	{ label: 'Settled', value: ExpenseGroupType.SETTLED },
	{ label: 'Cancelled', value: ExpenseGroupType.CANCELLED },
	{ label: 'Denied', value: ExpenseGroupType.DENIED },
	{ label: 'Awaiting Review', value: ExpenseGroupType.AWAITING_REVIEW },
	{ label: 'Reimburse', value: ExpenseGroupType.REIMBURSE }
];

export const enum ExpenseStatusType {
	AWAITING_REVIEW = 'AWAITING_REVIEW',
	PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
	DENIED = 'DENIED',
	NEEDS_MORE_INFO = 'NEEDS_MORE_INFO',
	SETTLED = 'SETTLED',
	APPROVED = 'APPROVED',
	ELIGIBLE_FOR_REIMBURSEMENT = 'ELIGIBLE_FOR_REIMBURSEMENT',
	RECEIPT_RECOMMENDED = 'RECEIPT_RECOMMENDED'
}

export const ExpenseStatusTypeOptions = [
	{ label: 'Awaiting Review', value: ExpenseStatusType.AWAITING_REVIEW },
	{ label: 'Partially Approved', value: ExpenseStatusType.PARTIALLY_APPROVED },
	{ label: 'Denied', value: ExpenseStatusType.DENIED },
	{ label: 'Needs More Info', value: ExpenseStatusType.NEEDS_MORE_INFO },
	{ label: 'Settled', value: ExpenseStatusType.SETTLED },
	{ label: 'Approved', value: ExpenseStatusType.APPROVED },
	{ label: 'Eligible For Reimbursement', value: ExpenseStatusType.ELIGIBLE_FOR_REIMBURSEMENT },
	{ label: 'Receipt Recommended', value: ExpenseStatusType.RECEIPT_RECOMMENDED }
];

export enum ExpenseActionType {
	ATTACH_DOCUMENTS = 'ATTACH_DOCUMENTS',
	PAYBACK = 'PAYBACK',
	CONTACT_US = 'CONTACT_US',
	SUBMIT = 'SUBMIT',
	CANCEL = 'CANCEL',
	REIMBURSE = 'REIMBURSE',
	DELETE = 'DELETE',
	FIX_MISTAKEN_WITHDRAWAL = 'FIX_MISTAKEN_WITHDRAWAL',
	ADD_TRAVEL_EXPENSE = 'ADD_TRAVEL_EXPENSE'
}

export enum ReimburseExpenseType {
	MEDICAL = 'Medical',
	DENTAL = 'Dental',
	DEPENDENT_CARE = 'Dependent Care',
	PHARMACY = 'Pharmacy',
	VISION = 'Vision',
	COMMUTER = 'Commuter',
	TRAVEL = 'Travel'
}

export enum TransactionAndExpenseDes {
	NEEDS_ADDITIONAL_INFO = 'needs additional info',
	HOLD_AWAITING_APPROVAL = 'hold, awaiting approval',
	ELIGIBLE_FOR_REIMBURSEMENT = 'eligible for reimbursement',
	RECEIPT_RECOMMENDED = 'receipt recommended',
	AWAITING_REIMBURSEMENT = 'awaiting reimbursement',
	PARTIALLY_REIMBURSED = 'partially reimbursed',
	ACTION_NEEDED = 'action needed'
}

export enum UploadScanStatus {
	IN_PROGRESS = 'IN_PROGRESS',
	FILE_ACCEPTED = 'FILE_ACCEPTED',
	FILE_REJECTED = 'FILE_REJECTED',
	ERRORED_OUT = 'ERRORED_OUT'
}

export enum DocumentUploadModule {
	EXPENSE = 'expense',
	EXPENSE_DRAFT = 'expense-draft'
}

export const LEGAL_COPY: any = {
	manual: {
		[ExpenseStatusType.DENIED]: `You may also submit a written appeal by fax to 650-297-7250. Please include with your written appeal the claim number identified above and a summary of why you believe your reimbursement request should be approved.

		If you appeal and your request is once again denied, you will have an opportunity to submit another appeal (2nd level appeal) to the plan administrator. Additional information regarding the 2nd level appeal will be found in the subsequent denial letter and also in your Summary Plan Description.
		
		If the 2nd level appeal is also denied in whole or part, you may have the right to sue in accordance with ERISA Section 502 (only for the Health FSA).`,

		[ExpenseStatusType.PARTIALLY_APPROVED]: `You may also submit a written appeal by fax to 650-297-7250. Please include with your written appeal the claim number identified above and a summary of why you believe your reimbursement request should be approved

		If you appeal and your request is once again denied, you will have an opportunity to submit another appeal (2nd level appeal) to the plan administrator. Additional information regarding the 2nd level appeal will be found in the subsequent denial letter and also in your Summary Plan Description.
		
		If the 2nd level appeal is also denied in whole or part, you may have the right to sue in accordance with ERISA Section 502 (only for the Health FSA).`,

		[ExpenseStatusType.NEEDS_MORE_INFO]: `If your claim is denied because you failed to submit the appropriate documentation by the due date, but you feel it should have been reimbursed, please contact support within 180 days of the due date identified above to appeal this decision.

		You may also submit written appeal by fax to 650-297-7250. Please include with your written appeal the claim number identified above and a summary of why you believe your reimbursement request should be approved.
		
		If you appeal and your request is once again denied, you will have an opportunity to submit another appeal (2nd level appeal) to the plan administrator. Additional information regarding the 2nd level appeal will be found in the subsequent denial letter and also in your Summary Plan Description.
		
		If the 2nd level appeal is also denied in whole or part, you may have the right to sue in accordance with ERISA Section 502 (only for the Health FSA).`
	} as Record<string, string>,
	transaction: {
		[ExpenseStatusType.DENIED]: `If your claim is denied because you failed to submit the appropriate documentation by the due date, but you feel it should have been reimbursed, please contact support within 180 days of the due date identified above to appeal this decision.

		You may also submit written appeal by fax to 650-297-7250. Please include with your written appeal the claim number identified above and a summary of why you believe your reimbursement request should be approved.
		
		If you appeal and your request is once again denied, you will have an opportunity to submit another appeal (2nd level appeal) to the plan administrator. Additional information regarding the 2nd level appeal will be found in the subsequent denial letter and also in your Summary Plan Description.
		
		If the 2nd level appeal is also denied in whole or part, you may have the right to sue in accordance with ERISA Section 502 (only for the Health FSA).`,
		[ExpenseStatusType.NEEDS_MORE_INFO]: `If your claim is denied because you failed to submit the appropriate documentation by the due date noted above, but you feel it should have been approved, please contact support within 180 days of the due date identified above to appeal this decision.

		You may also submit written appeal by fax to 650-297-7250. Please include with your written appeal the claim number identified above and a summary of why you believe your reimbursement request should be approved.
		
		If you appeal and your request is once again denied, you will have an opportunity to submit another appeal (2nd level appeal) to the plan administrator. Additional information regarding the 2nd level appeal will be found in the subsequent denial letter and also in your Summary Plan Description.
		
		If the 2nd level appeal is also denied in whole or part, you may have the right to sue in accordance with ERISA Section 502 (only for the Health FSA).`,
		[ExpenseStatusType.PARTIALLY_APPROVED]: `You may also submit a written appeal by fax to 650-297-7250. Please include with your written appeal the claim number identified above and a summary of why you believe your reimbursement request shoud be approved

		If you appeal and your request is once again denied, you will have an opportunity to submit another appeal (2nd level appeal) to the plan administrator. Additional information regarding the 2nd level appeal will be found in the subsequent denial letter and also in your Summary Plan Description.
		
		If the 2nd level appeal is also denied in whole or part, you may have the right to sue in accordance with ERISA Section 502 (only for the Health FSA).`
	} as Record<string, string>
};

export enum MfaStatus {
	ENABLE = 'enable',
	DISABLE = 'disable',
	SKIP_FOR_NOW = 'skip-for-now',
	SKIP_FOREVER = 'skip-forever'
}

export enum AccountPlanStatus {
	ENROLLED = 'enrolled',
	ACTIVE = 'active',
	SUSPENDED = 'suspended',
	GRACE = 'grace',
	RUNOUT = 'runout',
	CLOSED = 'close',
	PERM_CLOSED = 'perm-closed'
}

export const DEFAULT_PHONE_NUMBER = '+1234567890';
