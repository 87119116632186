/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import classes from './CreateExpense.module.scss';
import {
	AccountType,
	ExpenseGroupType,
	getSelectStyle,
	ReimburseExpenseType
} from '../../../constants/common';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { FormControl } from '../../common/FormControl';
import { Input, InputTypes } from '../../common/Input';
import { StyledLink, StyledLinkSizes } from '../../common/Link';
import { Button, ButtonTypes } from '../../common/Button';
import DatePicker from 'react-datepicker';
import { Loader } from '../../../components/common/Loader';
import { dateUtil } from '../../../utils/dateUtil';
import Modal from 'react-modal';
import { Incomplete, Warning } from '../../icons';
import drive from '../../../assets/img/TravelLight.svg';

import { expenseApi } from '../../../services/expenseService';
import { Banner, BannerTypes } from '../../common/Banner';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { transactionsApi } from '../../../services/transactionsService';
import { useSelector } from 'react-redux';

type StepProps = {
	nextStep: () => void;
	updateExpenseDetails: (details: Record<string, string | null>) => void;
	updateExpenseDetailsSuccess: (details: Record<string, string | boolean>) => void;
	expenseDetails: {
		expenseFormData: Record<string, string | null>;
		expenseDetailsSuccess: Record<string, string | boolean>;
	};
	onCancel: () => void;
};

type FormValues = {
	expense_type: string;
	transaction_date: string;
	name_of_merchant: string;
	health_expense_amount: string;
	expense_for: string;
	notes: string;
	has_travel_expense: string;
	mileage: string;
	other_travel_amount: string;
	start_date: string;
	end_date: string;
	is_dental_vision_expense: string;
	parent_expense_id: string;
	expense_related: string;
};

const defaultFormValue = {
	expense_type: '',
	transaction_date: '',
	name_of_merchant: '',
	health_expense_amount: '',
	expense_for: '',
	notes: '',
	has_travel_expense: 'N',
	mileage: '0',
	other_travel_amount: '0',
	start_date: '',
	end_date: '',
	is_dental_vision_expense: '',
	parent_expense_id: '',
	expense_related: ''
};

export const CreateExpense = ({
	nextStep,
	expenseDetails,
	updateExpenseDetails,
	updateExpenseDetailsSuccess,
	onCancel
}: StepProps) => {
	const [isLearnmore, setLearnmore] = useState<boolean>(false);
	const [hasTravelPotential, setHasTravelPotential] = useState<boolean>(false);
	const {
		control,
		setValue,
		getValues,
		reset,
		setError,
		clearErrors,
		handleSubmit,
		unregister,
		register,
		watch,
		formState: { isValid, errors }
	} = useForm<FormValues>({
		mode: 'onBlur',
		defaultValues: expenseDetails.expenseDetailsSuccess.expense_draft_id
			? expenseDetails.expenseFormData
			: defaultFormValue
	});

	const watchExpenseType = watch('expense_type');
	const watchHasTavelExpense = watch('has_travel_expense');
	const watchDatesFields = watch(['start_date', 'end_date']);
	watch(['mileage', 'other_travel_amount', 'name_of_merchant']);

	const [expenseTypeOptions, setExpenseTypeOptions] = useState<
		Array<{ value: string; label: string }>
	>([]);

	// const [expenseTypes, setExpenseTypes] = useState<
	// 	Array<{ type: string; plan_start_date: string; plan_end_date: string }>
	// >([]);

	const [expenseTypes, setExpenseTypes] = useState<
		Array<{ type: string; date_ranges: Array<{ start_date: string; end_date: string }> }>
	>([]);

	const [searchParams] = useSearchParams();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [globalError, setGlobalError] = useState<string>('');
	const [transactionDetails, setTransactionDetails] = useState<any>({});
	const [isTransactionLoading, setIsTransactionLoading] = useState<boolean>(false);

	const { state: locationState } = useLocation();

	const summaryData = useSelector((state: any) => state.summaryData);

	const navigate = useNavigate();

	const getExpenseType = () => {
		expenseApi
			.getExpenseType()
			.then(response => {
				setExpenseTypes(response.data.data.types);
				const mapExpenseTypeOptions = response.data.data.types.map((data: { type: string }) => {
					return {
						value: data.type,
						label: data.type
					};
				});
				setExpenseTypeOptions(mapExpenseTypeOptions);
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	// const getMinDate = () => {
	// 	const selectedExpense = expenseTypes.find(type => type.type == watchExpenseType);

	// 	if (selectedExpense) {
	// 		const allStartDate = selectedExpense?.date_ranges.map(date =>
	// 			dateUtil.day(date.start_date).utc()
	// 		);
	// 		const minDate = dateUtil.day.min(allStartDate || []);

	// 		return new Date(minDate.format());
	// 	}

	// 	return null;
	// };
	const getMaxDate = () => {
		const selectedExpense = expenseTypes.find(type => type.type == watchExpenseType);

		if (selectedExpense) {
			const allEndDate = selectedExpense?.date_ranges.map(date =>
				dateUtil.day(date.end_date).utc()
			);
			const maxDate = dateUtil.day.max(allEndDate || []);

			return dateUtil.day(maxDate).isBefore(dateUtil.day())
				? new Date(maxDate.format())
				: new Date();
		}

		return null;
	};

	const onDateOfServiceOrTravelChange = (
		e: string | Date | null,
		onChange: (...event: any[]) => void
	) => {
		const selectedExpense = expenseTypes.find(type => type.type == watchExpenseType);

		if (!e) {
			onChange(e);
			return;
		}

		e = new Date(dateUtil.day(e).startOf('day').format());

		if (
			selectedExpense?.date_ranges.some(day =>
				dateUtil.day(e).isBetween(day.start_date, day.end_date, 'day', '[]')
			)
		) {
			clearErrors('transaction_date');
		} else {
			setError('transaction_date', {
				type: 'range',
				message: 'Uh oh. Looks like this date is not within your plan year.'
			});
		}
		onChange(e);
	};

	const getExpenseGroup = () => {
		setIsLoading(true);
		transactionsApi
			.getExpenseByGroup(ExpenseGroupType.TRAVEL_POTENTIAL.toUpperCase(), { offset: 0, limit: 1 })
			.then(response => {
				setHasTravelPotential(!!response.data.data.length);
			})
			.catch(() => {});
	};

	const getExpenseDetails = (expenseId: string) => {
		setIsLoading(true);
		expenseApi
			.getExpense(expenseId)
			.then((response: any) => {
				setValue('expense_type', ReimburseExpenseType.TRAVEL);
				setValue('expense_for', response?.data.expense_details.expense_for);
				// setValue('name_of_merchant', response?.data.expense_details.merchant);
				// setValue('mileage', '');
				// setValue('other_travel_amount', '');

				setValue(
					'transaction_date',
					dateUtil
						.day(response?.data.expense_details.transaction_date * 1000)
						.startOf('day')
						.format()
				);

				// new Date(dateUtil.day(e).startOf('day').format());
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const getTransactionDetails = (transactionId: string) => {
		setIsTransactionLoading(true);
		expenseApi
			.getTransactionsDetails(transactionId)
			.then((response: any) => {
				const transactionData = response.data.data;
				setTransactionDetails(transactionData);
				setIsTransactionLoading(false);
				console.log(transactionDetails, response.data.data);
				if (!expenseDetails.expenseDetailsSuccess.expense_draft_id) {
					setValue('name_of_merchant', transactionData.merchant_details.merchant_name);
					setValue(
						'transaction_date',
						dateUtil
							.day(new Date(transactionData.date * 1000))
							.startOf('day')
							.format()
					);
					setValue('health_expense_amount', transactionData.amount);
					setValue('expense_for', transactionData.card_holder_name);
				}
			})
			.catch(() => {
				//navigate('/apps/expenses/summary');
			});
	};

	useEffect(() => {
		if (locationState?.id) {
			getExpenseDetails(locationState.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationState]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const transactionId = searchParams.get('transaction_id');
		if (transactionId) {
			getTransactionDetails(transactionId);
		}
		getExpenseType();
		getExpenseGroup();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		console.log(watchDatesFields);
		const [startDate, endDate] = watchDatesFields;

		const selectedExpense = expenseTypes.find(type => type.type == watchExpenseType);

		if (!selectedExpense) {
			return;
		}

		if (
			startDate &&
			!selectedExpense?.date_ranges.some(day =>
				dateUtil.day(startDate).isBetween(day.start_date, day.end_date, 'day', '[]')
			)
		) {
			setError('start_date', {
				type: 'range',
				message: 'Uh oh. Looks like this date is not within your plan year.'
			});
		} else if (endDate && dateUtil.day(startDate).isAfter(endDate)) {
			setError('start_date', {
				type: 'validInput',
				message: 'Start Date should be smaller or equal to End Date.'
			});
		} else {
			clearErrors('start_date');
		}

		if (
			endDate &&
			!selectedExpense?.date_ranges.some(day =>
				dateUtil.day(endDate).isBetween(day.start_date, day.end_date, 'day', '[]')
			)
		) {
			setError('end_date', {
				type: 'range',
				message: 'Uh oh. Looks like this date is not within your plan year.'
			});
		} else if (startDate && dateUtil.day(endDate).isBefore(startDate)) {
			setError('end_date', {
				type: 'validInput',
				message: 'End Date should be greater or equal to Start Date.'
			});
		} else {
			clearErrors('end_date');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchDatesFields[0], watchDatesFields[1]]);

	const getFormattedData = (data: any) => {
		const transactionId = searchParams.get('transaction_id');
		return {
			expense_type: data.expense_type,
			transaction_id: transactionId || '',
			start_date: data.start_date
				? dateUtil.getFormattedDate(data.start_date, 'YYYY-MM-DD') + 'T23:59:00Z'
				: '',
			end_date: data.end_date
				? dateUtil.getFormattedDate(data.end_date, 'YYYY-MM-DD') + 'T23:59:00Z'
				: '',
			transaction_date: data.transaction_date
				? dateUtil.getFormattedDate(data.transaction_date, 'YYYY-MM-DD') + 'T23:59:00Z'
				: '',
			name_of_merchant: data.name_of_merchant,
			health_expense_amount: Number(data.health_expense_amount as unknown as number),
			expense_for: data.expense_for,
			notes: data.notes || '',
			is_dental_vision_expense: data.is_dental_vision_expense,
			parent_expense_id: data.parent_expense_id || locationState?.id || '',
			travel_expense_details: {
				mileage: Number(data.mileage) || 0,
				other_travel_amount: Number(data.other_travel_amount) || 0
			}
		};
	};

	const jsonPatchRequest = (
		formData: Record<string, any>,
		draftData: Record<string, any>,
		pathKey = ''
	) => {
		const dataToUpdate: Array<{ op: string; path: string; value: string | number }> = [];
		const submittedDataKeys = Object.keys(formData);

		submittedDataKeys.forEach((key: string) => {
			if (formData[key] != draftData[key] && ['string', 'number'].includes(typeof formData[key])) {
				dataToUpdate.push({
					op: 'replace',
					path: `${pathKey}/${key}`,
					value: formData[key]
				});
			}
			if (typeof formData[key] == 'object') {
				const jsonPatchResponse = jsonPatchRequest(formData[key], draftData[key], `/${key}`);

				dataToUpdate.push(...jsonPatchResponse);
			}
		});
		return dataToUpdate;
	};
	const showDentalAppointment = () => {
		return (
			!locationState?.id &&
			summaryData.data.xfsa_summary.some((account: any) => account.type == AccountType.LFSA)
		);
	};
	const onSubmit = handleSubmit((data: FormValues) => {
		setIsLoading(true);
		window.scrollTo(0, 0);
		data = {
			...data,
			expense_for: data.expense_for.trim(),
			notes: data.notes.trim(),
			name_of_merchant: data.name_of_merchant.trim(),
			parent_expense_id: expenseDetails?.expenseFormData?.parent_expense_id || ''
		};

		if (data.expense_type == ReimburseExpenseType.TRAVEL && showDentalAppointment()) {
			data.is_dental_vision_expense = !!data.is_dental_vision_expense
				? data.is_dental_vision_expense
				: '0';
		}

		if (expenseDetails.expenseDetailsSuccess.expense_draft_id) {
			const formData = getFormattedData(data);
			const draftData = getFormattedData(expenseDetails.expenseFormData as FormValues);

			const jsonPatchResponse = jsonPatchRequest(formData, draftData, '');

			if (
				draftData.parent_expense_id &&
				(data.expense_related == '0' || data.expense_type != ReimburseExpenseType.TRAVEL)
			) {
				jsonPatchResponse.push({
					op: 'replace',
					path: `/parent_expense_id`,
					value: ''
				});

				data = { ...data, parent_expense_id: '' };
			}

			if (jsonPatchResponse?.length) {
				expenseApi
					.updateExpenseDraft(
						expenseDetails.expenseDetailsSuccess.expense_draft_id as string,
						jsonPatchResponse
					)
					.then(response => {
						setIsLoading(false);
						updateExpenseDetails(data);
						updateExpenseDetailsSuccess(response.data.data);
						nextStep();
					})
					.catch(() => {
						setIsLoading(false);
					});
			} else {
				updateExpenseDetails(data);
				nextStep();
			}
		} else {
			const draftData = getFormattedData(data);

			if (draftData?.parent_expense_id) {
				data.parent_expense_id = draftData?.parent_expense_id;
			}

			expenseApi
				.createDraft(draftData)
				.then(response => {
					setIsLoading(false);
					updateExpenseDetails(data);
					updateExpenseDetailsSuccess(response.data.data);
					nextStep();
				})
				.catch(error => {
					//console.log('Errorrr===>', error.response);
					if (error.response) {
						setGlobalError(error.response.data.error.message);
						window.scrollTo(0, 0);
					}
					setIsLoading(false);
				});
		}
	});

	if (isLoading || isTransactionLoading) {
		return <Loader />;
	}
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '100%',
			maxWidth: '564px',
			transform: 'translate(-50%, -50%)',
			padding: '0px',
			border: 'none',
			backgroundColor: '#fff'
		}
	};

	const isDentalExpenseEligible = () => {
		return (
			watchExpenseType == ReimburseExpenseType.TRAVEL &&
			getValues('is_dental_vision_expense') == '0' &&
			[...summaryData.data.xfsa_summary, ...summaryData.data?.hsa_summary].length == 1 &&
			summaryData.data?.xfsa_summary.length &&
			summaryData.data?.xfsa_summary[0]?.type == AccountType.LFSA
		);
	};
	const isNextDisable = () => {
		return (
			!isValid ||
			!!Object.keys(errors).length ||
			(getValues('expense_type') == ReimburseExpenseType.TRAVEL &&
				!+getValues('mileage') &&
				!+getValues('other_travel_amount')) ||
			(getValues('expense_type') == ReimburseExpenseType.TRAVEL &&
				!!+getValues('other_travel_amount') &&
				!getValues('name_of_merchant')?.trim().length) ||
			isDentalExpenseEligible()
		);
	};

	return (
		<div className="mt-32">
			<Modal
				isOpen={isLearnmore}
				style={customStyles}
				onRequestClose={() => {
					setLearnmore(false);
				}}
				contentLabel="Learn More Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<React.Fragment>
					<div className="modal-header">
						<div
							onClick={() => {
								setLearnmore(false);
							}}
							className="modal-close-btn-container"
						>
							<Incomplete></Incomplete>
						</div>
						<div className={`d-flex justify-content-center text-h2-325 text-white mt-32`}>
							Did you know?
						</div>
					</div>
					<div
						className={` ${classes['background-blue']} modal-body d-flex flex-column align-items-center`}
					>
						<div className={`d-flex justify-content-center`}>
							<img src={drive} width="200" />
						</div>
						<br />
						<div className={`text-base-350 text-primary py-8`}>
							The IRS allows you to use HSA/FSA dollars to pay for transportation costs for
							traveling to receive a health related service or product.
							<br /> <br /> You can include:
							<ul className={`text-sm-325`}>
								<li>Round trip mileage</li>
								<li>Ride share, taxi or public transportation costs</li>
								<li>Parking fees or tolls incurred</li>
								<li>
									Plane fare or hotel costs when the trip was primarily for, and essential to,
									medical care
								</li>
							</ul>
						</div>
						<br />

						<div className={` d-flex justify-content-center`}>
							<Button
								type={ButtonTypes.TERTIARY}
								onClick={() => {
									setLearnmore(false);
								}}
							>
								CONTINUE
							</Button>
						</div>
					</div>
				</React.Fragment>
			</Modal>
			<form onSubmit={onSubmit} className="py-16">
				<div className="row">
					<div className="cl-md-6">
						{globalError.length > 0 && (
							<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
								{globalError}
							</Banner>
						)}
						<p className="text-sm-325 py-12 text-primary">
							Please provide the information below to create an expense. Expenses paid from your FSA
							will require you to provide supporting documentation.
						</p>
						<Controller
							control={control}
							name="expense_type"
							rules={{
								required: 'expense type is required.'
							}}
							render={({ field: { value }, fieldState: { error } }) => (
								<>
									<FormControl label={() => 'Expense Type'} error={() => error?.message || ''}>
										<Select
											options={expenseTypeOptions}
											onChange={selected => {
												if (watchExpenseType && watchExpenseType != selected?.value) {
													reset({ ...defaultFormValue, expense_type: selected?.value || '' });
													setGlobalError('');
													navigate('.', { replace: true });
												} else {
													setValue('expense_type', selected?.value || '');
												}
											}}
											placeholder={'Select Expense Type'}
											value={value ? expenseTypeOptions.find(item => item.value == value) : null}
											isSearchable={false}
											styles={{
												control: getSelectStyle
											}}
										/>
									</FormControl>
								</>
							)}
						/>
					</div>
				</div>

				{watchExpenseType && watchExpenseType == ReimburseExpenseType.TRAVEL ? (
					<>
						{showDentalAppointment() && (
							<div className="py-32">
								<p className="text-base-325 pt-12 text-primary">
									Was this travel for a dental or vision appointment?
								</p>
								<div className="d-flex py-12">
									<div>
										<Controller
											control={control}
											name="is_dental_vision_expense"
											rules={{
												required: 'has travel expense is required.'
											}}
											render={({ field: {}, fieldState: {} }) => (
												<div className="d-flex align-items-center">
													<input
														className={classes['radio-button']}
														name="is_dental_vision_expense"
														onChange={e => {
															if (e.target.checked) {
																setValue('is_dental_vision_expense', '1', { shouldValidate: true });
															}
														}}
														value={'1'}
														type="radio"
														checked={getValues('is_dental_vision_expense') == '1'}
													/>
													<span className="text-base-325 px-4">Yes</span>
												</div>
											)}
										/>
									</div>
									<div className="px-16">
										<Controller
											control={control}
											name="is_dental_vision_expense"
											rules={{
												required: 'has travel expense is required.'
											}}
											render={({ field: {}, fieldState: {} }) => (
												<div className="d-flex align-items-center">
													<input
														name="is_dental_vision_expense"
														className={classes['radio-button']}
														onChange={e => {
															if (e.target.checked) {
																setValue('is_dental_vision_expense', '0', { shouldValidate: true });
															}
														}}
														value={'0'}
														type="radio"
														checked={getValues('is_dental_vision_expense') == '0'}
													/>
													<span className="text-base-325 px-4">No</span>
												</div>
											)}
										/>
									</div>
								</div>
								{isDentalExpenseEligible() && (
									<Banner type={BannerTypes.NEGATIVE}>This expense is not eligible.</Banner>
								)}
							</div>
						)}

						<p className="d-flex justify-content-between pb-16">
							<span className="text-h5-350 text-primary">Travel</span>
							<span className="px-12">
								<StyledLink
									size={StyledLinkSizes.SM}
									onClick={() => {
										setLearnmore(true);
									}}
								>
									Learn More
								</StyledLink>
							</span>
						</p>
						<div className="row">
							<div className="cl-md-6">
								<Controller
									control={control}
									name="transaction_date"
									rules={{
										required: 'Date of travel is required.'
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<>
											<FormControl
												label={() => 'Date of Travel'}
												error={() => error?.message || ''}
											>
												<DatePicker
													className="zenda-datepicker"
													showPopperArrow={false}
													selected={value ? new Date(value) : null}
													onChange={e => onDateOfServiceOrTravelChange(e, onChange)}
													placeholderText={'Date of Travel'}
													dropdownMode="select"
													showMonthDropdown
													showYearDropdown
													adjustDateOnChange
													dateFormat="MM/dd/yyyy"
													// minDate={getMinDate()}
													maxDate={getMaxDate()}
												/>
											</FormControl>
										</>
									)}
								/>
							</div>

							<div className="cl-md-6">
								<Controller
									control={control}
									name="mileage"
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<>
											<FormControl
												label={() => 'Mileage'}
												error={() => error?.message || ''}
												caption={'Enter miles traveled round trip.'}
											>
												<Input
													onchange={evt => {
														// if (evt.target.value?.length <= 40) {
														// 	onChange(evt);
														// }
														const inputValue = evt.target.value;

														if (
															new RegExp(/^[0-9]*\.?[0-9]{0,2}$/).test(inputValue) &&
															Number(inputValue) < 10000
														) {
															onChange(evt);
														}
													}}
													isIncomplete={error ? true : false}
													isClearable={true}
													type={InputTypes.TEXT}
													value={value || ''}
													placeholder={'Enter Mileage'}
												/>
											</FormControl>
										</>
									)}
								/>
							</div>

							<div className="cl-md-6">
								<Controller
									control={control}
									name="other_travel_amount"
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<>
											<FormControl
												label={() => 'Other Expenses'}
												error={() => error?.message || ''}
											>
												<Input
													onchange={evt => {
														const inputValue = evt.target.value;

														if (
															new RegExp(/^[0-9]*\.?[0-9]{0,2}$/).test(inputValue) &&
															Number(inputValue) < 10000
														) {
															onChange(evt);
														}
													}}
													isIncomplete={error ? true : false}
													isAmount={true}
													isClearable={true}
													type={InputTypes.TEXT}
													value={value || ''}
													placeholder={'Enter Expense'}
												/>
											</FormControl>
										</>
									)}
								/>
							</div>
							<div className="cl-md-6">
								<Controller
									control={control}
									name="name_of_merchant"
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<>
											<FormControl
												label={() => 'What were the other expenses for?'}
												error={() => error?.message || ''}
												caption={'Cab, ride share, parking, tolls, etc.'}
											>
												<Input
													onchange={evt => {
														if (evt.target.value?.length <= 45) {
															onChange(evt);
														}
													}}
													isIncomplete={error ? true : false}
													isClearable={true}
													type={InputTypes.TEXT}
													value={value || ''}
													placeholder={''}
												/>
											</FormControl>
										</>
									)}
								/>
							</div>

							<div className="cl-md-6">
								<Controller
									control={control}
									name="expense_for"
									rules={{
										required: 'For is required.',
										validate: value => !!value.trim()
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<>
											<FormControl
												label={() => 'Who was this for?'}
												error={() => error?.message || ''}
											>
												<Input
													onchange={evt => {
														if (evt.target.value?.length <= 45) {
															onChange(evt);
														}
													}}
													isIncomplete={error ? true : false}
													isClearable={true}
													type={InputTypes.TEXT}
													value={value || ''}
													placeholder={''}
												/>
											</FormControl>
										</>
									)}
								/>
							</div>
						</div>
						<div>
							<Controller
								control={control}
								name="notes"
								rules={{}}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<>
										<FormControl label={() => 'Notes'} error={() => error?.message || ''}>
											<textarea
												maxLength={500}
												onChange={onChange}
												value={value}
												rows={10}
												cols={50}
											></textarea>
										</FormControl>
									</>
								)}
							/>
						</div>

						{!locationState?.id && hasTravelPotential && (
							<div className="py-32">
								<p className="text-base-325 pt-12 text-primary">
									Is this travel related to a health expense you paid through your debit card?
								</p>
								<div className="d-flex py-12">
									<div>
										<Controller
											control={control}
											name="expense_related"
											rules={{
												required: 'This field is required.'
											}}
											render={({ field: {}, fieldState: {} }) => (
												<div className="d-flex align-items-center">
													<input
														className={classes['radio-button']}
														name="hasTravel"
														onChange={e => {
															if (e.target.checked) {
																setValue('expense_related', '1', { shouldValidate: true });
															}
														}}
														value={'Y'}
														type="radio"
														checked={getValues('expense_related') == '1'}
													/>
													<span className="text-base-325 px-4">Yes</span>
												</div>
											)}
										/>
									</div>
									<div className="px-16">
										<Controller
											control={control}
											name="expense_related"
											rules={{
												required: 'This field is required.'
											}}
											render={({ field: {}, fieldState: {} }) => (
												<div className="d-flex align-items-center">
													<input
														name="hasTravel"
														className={classes['radio-button']}
														onChange={e => {
															if (e.target.checked) {
																setValue('expense_related', '0', { shouldValidate: true });
															}
														}}
														value={'N'}
														type="radio"
														checked={getValues('expense_related') == '0'}
													/>
													<span className="text-base-325 px-4">No</span>
												</div>
											)}
										/>
									</div>
								</div>
							</div>
						)}
					</>
				) : (
					''
				)}

				<hr />
				{watchExpenseType && watchExpenseType != ReimburseExpenseType.TRAVEL ? (
					<>
						<p className="text-h4-325 pb-16 text-primary">Expense Info</p>
						<div className="row">
							{watchExpenseType != ReimburseExpenseType.DEPENDENT_CARE ? (
								<div className="cl-md-6">
									<Controller
										control={control}
										name="transaction_date"
										rules={{
											required: 'Date of service is required.'
										}}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<>
												<FormControl
													label={() => 'Date of Service'}
													error={() => error?.message || ''}
												>
													<DatePicker
														className="zenda-datepicker"
														showPopperArrow={false}
														selected={value ? new Date(value) : null}
														onChange={e => onDateOfServiceOrTravelChange(e, onChange)}
														placeholderText={'Date of Service'}
														dropdownMode="select"
														showMonthDropdown
														showYearDropdown
														adjustDateOnChange
														dateFormat="MM/dd/yyyy"
														// minDate={getMinDate()}
														maxDate={getMaxDate()}
													/>
												</FormControl>
											</>
										)}
									/>
								</div>
							) : (
								''
							)}

							{watchExpenseType == ReimburseExpenseType.DEPENDENT_CARE ? (
								<div className="cl-md-6">
									<Controller
										control={control}
										name="start_date"
										rules={{
											required: 'Start Date is required.'
										}}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<>
												<FormControl label={() => 'Start Date'} error={() => error?.message || ''}>
													<DatePicker
														className="zenda-datepicker"
														showPopperArrow={false}
														selected={value ? new Date(value) : null}
														onChange={e => {
															e = new Date(dateUtil.day(e).startOf('day').format());
															onChange(e);
														}}
														placeholderText={'Start Date'}
														dropdownMode="select"
														showMonthDropdown
														showYearDropdown
														adjustDateOnChange
														dateFormat="MM/dd/yyyy"
														// minDate={getMinDate()}
														maxDate={getMaxDate()}
													/>
												</FormControl>
											</>
										)}
									/>
								</div>
							) : (
								''
							)}

							{watchExpenseType == ReimburseExpenseType.DEPENDENT_CARE ? (
								<div className="cl-md-6">
									<Controller
										control={control}
										name="end_date"
										rules={{
											required: 'End Date is required.'
										}}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<>
												<FormControl label={() => 'End Date'} error={() => error?.message || ''}>
													<DatePicker
														className="zenda-datepicker"
														showPopperArrow={false}
														selected={value ? new Date(value) : null}
														onChange={e => {
															e = new Date(dateUtil.day(e).startOf('day').format());
															onChange(e);
														}}
														placeholderText={'End Date'}
														dropdownMode="select"
														showMonthDropdown
														showYearDropdown
														adjustDateOnChange
														dateFormat="MM/dd/yyyy"
														minDate={new Date(getValues('start_date')) || null}
														maxDate={getMaxDate()}
													/>
												</FormControl>
											</>
										)}
									/>
								</div>
							) : (
								''
							)}

							<div className="cl-md-6">
								<Controller
									control={control}
									name="name_of_merchant"
									rules={{
										required: 'Merchant name is required.',
										validate: value => !!value.trim()
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<>
											<FormControl
												label={() => 'Name of Merchant'}
												error={() => error?.message || ''}
											>
												<Input
													onchange={evt => {
														if (evt.target.value?.length <= 100) {
															onChange(evt);
														}
													}}
													isIncomplete={error ? true : false}
													isClearable={true}
													type={InputTypes.TEXT}
													value={value || ''}
													placeholder={'Merchant Name'}
												/>
											</FormControl>
										</>
									)}
								/>
							</div>

							<div className="cl-md-6">
								<Controller
									control={control}
									name="health_expense_amount"
									rules={{
										required: 'Amount is required.'
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<>
											<FormControl
												label={() => 'Health Expense Amount'}
												error={() => error?.message || ''}
											>
												<Input
													onchange={evt => {
														const inputValue = evt.target.value;

														if (new RegExp(/^[0-9]*\.?[0-9]{0,2}$/).test(inputValue)) {
															if (+inputValue === 0) {
																setError('health_expense_amount', {
																	type: 'invalidInput',
																	message: 'Amount should be greater than 0.'
																});
															} else {
																clearErrors('health_expense_amount');
															}
															onChange(evt);
														}
													}}
													onFocus={() => {
														if (Number(getValues('health_expense_amount')) == 0)
															setValue('health_expense_amount', '');
													}}
													isIncomplete={error ? true : false}
													isAmount={true}
													isClearable={true}
													type={InputTypes.TEXT}
													value={value}
													placeholder={'Amount'}
												/>
											</FormControl>
										</>
									)}
								/>
							</div>

							<div className="cl-md-6">
								<Controller
									control={control}
									name="expense_for"
									rules={{
										required: 'For is required.',
										validate: value => !!value.trim()
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<>
											<FormControl
												label={() => 'Who was this for?'}
												error={() => error?.message || ''}
											>
												<Input
													onchange={evt => {
														if (evt.target.value?.length <= 45) {
															onChange(evt);
														}
													}}
													isIncomplete={error ? true : false}
													isClearable={true}
													type={InputTypes.TEXT}
													value={value || ''}
													placeholder={''}
												/>
											</FormControl>
										</>
									)}
								/>
							</div>
						</div>

						<div>
							<Controller
								control={control}
								name="notes"
								rules={{}}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<>
										<FormControl label={() => 'Notes'} error={() => error?.message || ''}>
											<textarea
												maxLength={500}
												onChange={onChange}
												value={value}
												rows={10}
												cols={50}
											></textarea>
										</FormControl>
									</>
								)}
							/>
						</div>
						<hr />

						{watchExpenseType != ReimburseExpenseType.DEPENDENT_CARE ? (
							<>
								<p className="d-flex justify-content-between pb-16">
									<span className="text-h4-325 text-primary">Travel</span>
									<span className="px-12">
										<StyledLink
											size={StyledLinkSizes.SM}
											onClick={() => {
												setLearnmore(true);
											}}
										>
											Learn More
										</StyledLink>
									</span>
								</p>
								<p className="text-sm-325 text-primary">
									You can get reimbursed for any travel related costs!
								</p>
								<p className="text-base-700 pt-12 text-primary">Did you travel for this expense?</p>
								<div className="d-flex py-12">
									<div>
										<Controller
											control={control}
											name="has_travel_expense"
											rules={{
												required: 'has travel expense is required.'
											}}
											render={({ field: {}, fieldState: {} }) => (
												<div className="d-flex align-items-center">
													<input
														className={classes['radio-button']}
														name="hasTravel"
														onChange={e => {
															if (e.target.checked) {
																setValue('has_travel_expense', 'Y');
															}
															register('mileage', {
																value: expenseDetails.expenseFormData.mileage
																	? String(expenseDetails.expenseFormData.mileage)
																	: '0'
															});
															register('other_travel_amount', {
																value: expenseDetails.expenseFormData.other_travel_amount
																	? String(expenseDetails.expenseFormData.other_travel_amount)
																	: '0'
															});
														}}
														value={'Y'}
														type="radio"
														checked={getValues('has_travel_expense') == 'Y'}
													/>
													<span className="text-base-325 px-4">Yes</span>
												</div>
											)}
										/>
									</div>
									<div className="px-16">
										<Controller
											control={control}
											name="has_travel_expense"
											rules={{
												required: 'has travel expense is required.'
											}}
											render={({ field: {}, fieldState: {} }) => (
												<div className="d-flex align-items-center">
													<input
														name="hasTravel"
														className={classes['radio-button']}
														onChange={e => {
															if (e.target.checked) {
																setValue('has_travel_expense', 'N');
															}
															unregister(['mileage', 'other_travel_amount']);
														}}
														value={'N'}
														type="radio"
														checked={getValues('has_travel_expense') == 'N'}
													/>
													<span className="text-base-325 px-4">No</span>
												</div>
											)}
										/>
									</div>
								</div>
								{watchHasTavelExpense == 'Y' ? (
									<div className="row">
										<div className="cl-md-6">
											<Controller
												control={control}
												name="mileage"
												rules={{
													required: 'mileage is required.'
												}}
												render={({ field: { onChange, value }, fieldState: { error } }) => (
													<>
														<FormControl
															label={() => 'Mileage'}
															error={() => error?.message || ''}
															caption={'Enter miles traveled round trip.'}
														>
															<Input
																onchange={evt => {
																	// if (evt.target.value?.length <= 40) {
																	// 	onChange(evt);
																	// }
																	const inputValue = evt.target.value;

																	if (
																		new RegExp(/^[0-9]*\.?[0-9]{0,2}$/).test(inputValue) &&
																		Number(inputValue) < 10000
																	) {
																		onChange(evt);
																	}
																}}
																isIncomplete={error ? true : false}
																isClearable={true}
																type={InputTypes.TEXT}
																value={value || ''}
																placeholder={'Enter Mileage'}
															/>
														</FormControl>
													</>
												)}
											/>
										</div>

										<div className="cl-md-6">
											<Controller
												control={control}
												name="other_travel_amount"
												rules={{
													required: 'Other expense is required.'
												}}
												render={({ field: { onChange, value }, fieldState: { error } }) => (
													<>
														<FormControl
															label={() => 'Other Expenses'}
															error={() => error?.message || ''}
															caption={'Cab, ride share, parking, tolls, etc.'}
														>
															<Input
																onchange={evt => {
																	const inputValue = evt.target.value;

																	if (
																		new RegExp(/^[0-9]*\.?[0-9]{0,2}$/).test(inputValue) &&
																		Number(inputValue) < 10000
																	) {
																		onChange(evt);
																	}
																}}
																isIncomplete={error ? true : false}
																isAmount={true}
																isClearable={true}
																type={InputTypes.TEXT}
																value={value || ''}
																placeholder={'Enter Expense'}
															/>
														</FormControl>
													</>
												)}
											/>
										</div>
									</div>
								) : (
									''
								)}

								<hr />
							</>
						) : (
							''
						)}
					</>
				) : (
					''
				)}

				<div className={`row ${classes['reverse-order']}`}>
					<div className=" d-flex flex-column cl-md-6 pt-24">
						<Button
							onClick={e => {
								onCancel();
								e.preventDefault();
							}}
							type={ButtonTypes.SECONDARY}
						>
							Cancel
						</Button>
					</div>
					{watchExpenseType ? (
						<div className={`d-flex flex-column cl-md-6 pt-24 ${classes.order2}`}>
							<Button type={ButtonTypes.PRIMARY} isDisabled={isNextDisable()}>
								Next
							</Button>
						</div>
					) : (
						''
					)}
				</div>
			</form>
		</div>
	);
};
