import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import classes from './PasscodeVerification.module.scss';
import { Button, ButtonTypes } from '../../common/Button';
import { FormControl } from '../../common/FormControl';
import { Banner, BannerTypes } from '../../common/Banner';
import { Warning } from '../../icons';
import UserPool from '../../../utils/UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ForgotPasswordData } from '../../../types/forgotPassword.types';
import { StyledLink } from '../../common/Link';
import ReactInputVerificationCode from 'react-input-verification-code';
import { Input, InputTypes } from '../../common/Input';
import { PasswordHint } from '../../common/PasswordHint';
import { useNavigate } from 'react-router-dom';
import { localStorageUtil } from '../../../utils/localStorageUtil';
import { StyledLinkSizes, StyledLinkTypes } from '../../common/Link/StyledLink.constants';
import passwordSvg from '../../../assets/img/lock.svg';
import { ControlType } from '../../common/FormControl/FormControl.constants';

type PasscodeVerificationProps = {
	forgotPasswordData: ForgotPasswordData;
	onUpdateData: (updatedData: ForgotPasswordData) => void;
	onNextStep: () => void;
	onPrevStep: () => void;
};

interface IPasscodeVerification {
	passcode: string;
	password: string;
}

export const PasscodeVerification = ({
	forgotPasswordData,
	onPrevStep
}: // onUpdateData,
// onNextStep
PasscodeVerificationProps) => {
	//state to show error banner when api fails
	const [customError, setCustomError] = useState({ code: '', message: '' });
	const [isPasswordEyeOpen, setPasswordEye] = useState(false);
	const navigate = useNavigate();

	//form with default values
	const {
		handleSubmit,
		control,
		setValue,
		reset,
		formState: { isValid }
	} = useForm<IPasscodeVerification>({
		defaultValues: {
			passcode: '',
			password: ''
		},
		mode: 'onChange'
	});

	// Hit the aws cognito api again to get verification code
	const getPasscode = () => {
		setCustomError({ code: '', message: '' });
		const cognitoUser = new CognitoUser({
			Username: forgotPasswordData.email?.toLowerCase().trim(),
			Pool: UserPool
		});

		cognitoUser.forgotPassword({
			onSuccess: function () {},
			onFailure: function (err) {
				setCustomError({ message: err.message || JSON.stringify(err), code: '' });
			}
		});
	};

	const redirectUser = () => {
		localStorageUtil.clearTokens();
		navigate('/login');
	};

	const handleForm = handleSubmit((data: IPasscodeVerification) => {
		setCustomError({ code: '', message: '' });
		const cognitoUser = new CognitoUser({
			Username: forgotPasswordData.email?.toLowerCase().trim(),
			Pool: UserPool
		});

		const verificationCode = data?.passcode || '';
		cognitoUser.confirmPassword(verificationCode, data.password.trim(), {
			onSuccess() {
				redirectUser();
			},
			onFailure(err) {
				setCustomError({ message: err.message || JSON.stringify(err), code: '' });
				reset();
			}
		});
	});

	return (
		<div className={classes.card}>
			<div className="mt-64">
				<img
					onClick={() => {
						navigate('/');
					}}
					src={passwordSvg}
					width="250px"
					alt=""
				></img>
			</div>
			{customError && customError.message && (
				<span className={classes.banner}>
					<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
						{customError.message}
					</Banner>
				</span>
			)}
			<div className="mt-48">
				<p className={`text-h2-325 ${classes.title}`}>Reset Password</p>
			</div>
			<p className={`${classes.info} mt-12 text-center`}>
				We sent a security code to: {forgotPasswordData.email?.toLowerCase().trim()}{' '}
			</p>
			{/* <p className={`${classes.info}`}>{forgotPasswordData.email?.toLowerCase().trim()}</p> */}
			<p className={`${classes.info} text-center mt-12`}>
				Enter the code to verify your identity, then enter a new password.
			</p>
			<p className={`${classes.info} text-center mt-12`}>
				<span className="pr-16">Lost access to email?</span>
				<StyledLink
					type={StyledLinkTypes.TERTIARY}
					size={StyledLinkSizes.LG}
					href="mailto:support@incommbenefits.com"
				>
					CONTACT US
				</StyledLink>{' '}
			</p>
			<form autoComplete="off" className={classes.form}>
				<Controller
					control={control}
					name="passcode"
					rules={{
						required: 'Passcode is required.',
						minLength: { value: 6, message: 'Please enter 6 digit Passcode.' }
					}}
					render={({ field: { onChange, value }, fieldState: {} }) => (
						<>
							<FormControl
								controlType={ControlType.LIGHT}
								label={() => 'Your 6 Digit Code'}
								caption={() => 'Make sure to check your spam folder.'}
							>
								<ReactInputVerificationCode
									value={value}
									length={6}
									placeholder={''}
									onChange={onChange}
									autoFocus={true}
								/>
							</FormControl>
						</>
					)}
				/>
				<Controller
					control={control}
					name="password"
					rules={{
						required: 'Password is required.',
						minLength: { value: 8, message: 'Password is required.' },
						pattern: {
							value:
								/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]).{8,}$/,
							message: ''
						}
					}}
					render={({ field: { onChange, onBlur, value }, fieldState: { error, isDirty } }) => (
						<>
							<FormControl controlType={ControlType.LIGHT} label={() => 'New password'}>
								<Input
									id="newpassword"
									isClearable={true}
									onClear={() => {
										setValue('password', '', { shouldValidate: true });
									}}
									isIncomplete={error ? true : false}
									isTypePassword={true}
									onBlur={onBlur}
									onchange={onChange}
									onChangeType={() => {
										setPasswordEye(!isPasswordEyeOpen);
									}}
									type={isPasswordEyeOpen ? InputTypes.TEXT : InputTypes.PASSWORD}
									value={value}
								/>
							</FormControl>
							{isDirty && <PasswordHint control={control} />}
						</>
					)}
				/>

				<div className={`d-flex flex-column pt-24 mt-32  gap-16 w-100`}>
					<Button onClick={handleForm} isDisabled={!isValid} type={ButtonTypes.TERTIARY}>
						Verify and Reset
					</Button>
					<Button
						onClick={e => {
							e.preventDefault();
							onPrevStep();
						}}
						type={ButtonTypes.PRIMARY}
					>
						Back
					</Button>
				</div>
			</form>
			<span className={`d-flex mt-16 p-12 ${classes.info}`}>
				<p className="text-base-325 pr-16">Didn't get a verification code?</p>
				<StyledLink type={StyledLinkTypes.TERTIARY} onClick={getPasscode}>
					Resend
				</StyledLink>
			</span>
		</div>
	);
};
