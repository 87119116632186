import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import classes from './EmailCheck.module.scss';
import { Button, ButtonTypes } from '../../common/Button';
import { FormControl } from '../../common/FormControl';
import { Banner, BannerTypes } from '../../common/Banner';
import { Input, InputTypes } from '../../common/Input';
import { Warning } from '../../icons';
import UserPool from '../../../utils/UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ForgotPasswordData } from '../../../types/forgotPassword.types';
import { StyledLink } from '../../common/Link';
import { StyledLinkTypes } from '../../common/Link/StyledLink.constants';
import { useNavigate } from 'react-router-dom';
import logoSvg from '../../../assets/img/Incomm.svg';
import { ControlType } from '../../common/FormControl/FormControl.constants';

type EmailCheckProps = {
	forgotPasswordData?: ForgotPasswordData;
	onUpdateData: (updatedData: ForgotPasswordData) => void;
	onNextStep: () => void;
};
export const EmailCheck = ({ onUpdateData, onNextStep }: EmailCheckProps) => {
	//state to show error banner when api fails
	const [customError, setCustomError] = useState({ code: '', message: '' });

	const navigate = useNavigate();

	//form with default values
	const {
		handleSubmit,
		control,
		setValue,
		formState: { isValid }
	} = useForm<{ email: string }>({
		defaultValues: {
			email: ''
		}
	});

	const handleForm = handleSubmit((data: { email: string }) => {
		setCustomError({ code: '', message: '' });
		const cognitoUser = new CognitoUser({
			Username: data.email.toLowerCase().trim(),
			Pool: UserPool
		});

		cognitoUser.forgotPassword({
			onSuccess: function (verificationCode) {
				// successfully initiated reset password request
				console.log('CodeDeliveryData from forgotPassword: ' + verificationCode);
				onUpdateData({ email: data.email.toLowerCase().trim() });
				onNextStep();
			},
			onFailure: function (err) {
				setCustomError({ message: err.message || JSON.stringify(err), code: '' });
			}
		});
		console.log(data);
	});

	return (
		<div className={classes.card}>
			<div className="mt-64">
				<img
					onClick={() => {
						navigate('/');
					}}
					src={logoSvg}
					width="250px"
					alt="Incomm benefits logo"
				></img>
			</div>

			{customError && customError.message && (
				<span className={classes.banner}>
					<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
						{customError.message}
					</Banner>
				</span>
			)}
			<div>
				<p className={`${classes['text-white']} text-h4-325 p-48`}>Forgot password?</p>
			</div>
			<form className={classes.form} autoComplete="off">
				<Controller
					control={control}
					name="email"
					rules={{
						required: 'Email is required.',
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
							message: 'Email is not valid.'
						}
					}}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<>
							<FormControl
								controlType={ControlType.LIGHT}
								label={() => 'Email Address'}
								error={() => error?.message}
							>
								<Input
									id="fp-email-address"
									onchange={onChange}
									onClear={() => {
										setValue('email', '');
									}}
									isIncomplete={error ? true : false}
									isClearable={true}
									type={InputTypes.EMAIL}
									value={value}
								/>
							</FormControl>
							{/* <p className={`text-sm-325 ${classes['lost-text']}`}>
								If you have lost access to your email,{' '}
								<StyledLink
									type={StyledLinkTypes.TERTIARY}
									size={StyledLinkSizes.SM}
									href="mailto:support@incommbenefits.com"
								>
									CONTACT US
								</StyledLink>{' '}
								for assistance.
							</p> */}
						</>
					)}
				/>

				<div className={` d-flex flex-column mt-48 w-100`}>
					<Button onClick={handleForm} type={ButtonTypes.TERTIARY} isDisabled={!isValid}>
						RESET PASSWORD
					</Button>
				</div>
				<p className="mt-16 p-8">
					<StyledLink
						type={StyledLinkTypes.TERTIARY}
						onClick={() => {
							navigate('/login');
						}}
					>
						Back to Login
					</StyledLink>
				</p>
			</form>
		</div>
	);
};
