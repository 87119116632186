import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const backgroundSlice = createSlice({
	name: 'backgroundSlice',
	initialState: {
		backgroundDark: false,
		largeLayoutHeader: false
	},
	reducers: {
		changeBackground: (state, action: PayloadAction<boolean>) => {
			state.backgroundDark = action.payload;
		},
		largeLayout: (state, action: PayloadAction<boolean>) => {
			state.largeLayoutHeader = action.payload;
		}
	}
});

export default backgroundSlice.reducer;
export const { changeBackground, largeLayout } = backgroundSlice.actions;
