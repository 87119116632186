/* eslint-disable max-lines */
import React, { useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import classes from './StepOne.module.scss';
import { Button, ButtonTypes } from '../../common/Button';
import { FormControl } from '../../common/FormControl';
import { Input, InputTypes } from '../../common/Input';
import DatePicker from 'react-datepicker';
import { StyledLink } from '../../common/Link';
import { dateUtil } from '../../../utils/dateUtil';
import { signupApi } from '../../../services/signupApi';
import { Banner, BannerTypes } from '../../../components/common/Banner';
import Warning from '../../../components/icons/Warning';
import { UserData } from '../../../types/signup.types';
import { StyledLinkSizes, StyledLinkTypes } from '../../common/Link/StyledLink.constants';
import searchSvg from '../../../assets/img/search.svg';
import { useNavigate } from 'react-router-dom';
import { ControlType } from '../../common/FormControl/FormControl.constants';

type StepOneFormValues = {
	email: string;
	dob: string;
	ssn: string;
};

type StepProps = {
	onNextStep: () => void;
	onPreviousStep: () => void;
	userData: UserData;
	setUserData: (data: UserData) => void;
};
export const StepOne = ({ onNextStep, userData, setUserData }: StepProps) => {
	const [isPasswordEyeOpen, setPasswordEye] = useState(false);
	const emptyDivRef = useRef<any>(null);
	//state to show error banner when api fails
	const [signupError, setSignupError] = useState({ code: '', message: '' });

	const navigate = useNavigate();

	//form with default values
	const {
		handleSubmit,
		control,
		setValue
		// reset
		//formState: { errors }
	} = useForm<StepOneFormValues>({
		defaultValues: userData?.email?.toLowerCase()
			? userData
			: {
					email: '',
					dob: '',
					ssn: ''
			  }
	});

	// useEffect(() => {
	// 	(window as any).gtag('event', 'page_view', {
	// 		app_name: 'Zenda-web',
	// 		page_title: 'Onboarding_employee_details',
	// 		// screen_name: 'Onboarding_employee_details',
	// 		send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || ''
	// 	});
	// }, []);

	const onSubmit = handleSubmit((data: StepOneFormValues) => {
		setSignupError({ code: '', message: '' });
		const postData = {
			...data,
			email: data?.email?.toLowerCase().trim(),
			dob: dateUtil.getFormattedDate(data.dob),
			sent_at: dateUtil.getEpochTime()
		};
		signupApi
			.preSignup(postData)
			.then((response: any) => {
				const tmpUserData = { ...userData };
				tmpUserData.email = postData.email?.toLowerCase().trim();
				tmpUserData.company_name = response.data.company_name;
				tmpUserData.ssn = data.ssn;
				tmpUserData.dob = data.dob;
				setUserData(tmpUserData);
				onNextStep();
			})
			.catch((error: any) => {
				// set error to show banner
				// reset();
				setSignupError(error.response.data.error);
			});
	});

	const errorMessage = () => {
		if (signupError.code == 'EXISTING_USER') {
			return signupError.message
				.split('LOG IN')
				.flatMap(item => [
					item,
					<StyledLink href="/login" isOpenInNewTab={false}>
						Log In
					</StyledLink>
				])
				.slice(0, -1);
		} else if (
			signupError.message ==
			'We were unable to locate an HSA for you. Please contact us for assistance.'
		) {
			return (
				<React.Fragment>
					We were unable to locate an HSA for you. Please{' '}
					<StyledLink size={StyledLinkSizes.SM} href="mailto:support@incommbenefits.com">
						contact us
					</StyledLink>{' '}
					for assistance.
				</React.Fragment>
			);
		} else if (signupError.code == 'ATTEMPTS_EXCEEDED') {
			return (
				<React.Fragment>
					Sign up attempts exceeded. Please contact{' '}
					<StyledLink size={StyledLinkSizes.SM} href="mailto:support@incommbenefits.com">
						Customer Support.
					</StyledLink>{' '}
					for assistance.
				</React.Fragment>
			);
		} else if (signupError.code == 'USER_NOT_FOUND') {
			return (
				<React.Fragment>
					We were unable to locate an account for you. Please{' '}
					<StyledLink size={StyledLinkSizes.SM} href="mailto:support@incommbenefits.com">
						contact us
					</StyledLink>{' '}
					for assistance.
				</React.Fragment>
			);
		} else {
			return signupError.message;
		}
	};
	return (
		<div className="pt-32">
			<div className={classes.header}>
				<img src={searchSvg} width="200px" alt=""></img>
				<p className={`text-center text-h2-325 ${classes.title} mt-48`}>Let's find your account.</p>
			</div>
			<div className={classes.card}>
				{signupError && signupError.message && (
					<span className={classes.banner}>
						<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
							<div>{errorMessage()}</div>
						</Banner>
					</span>
				)}

				<form onSubmit={onSubmit} className={classes.form} autoComplete="off">
					<p className={`${classes.info} mb-48 pt-8`}>
						Please enter your information below. Please note that InComm Benefits can only be used
						through your employer.
					</p>

					<div>
						<Controller
							control={control}
							name="ssn"
							rules={{
								required: 'Error: SSN is required.',
								minLength: { value: 4, message: 'Error: Valid SSN is required' },
								maxLength: { value: 4, message: 'Error: Valid SSN is required ' }
							}}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<>
									<FormControl
										controlType={ControlType.LIGHT}
										label={() => 'Last 4 digits of your Social Security Number'}
										error={() => error?.message || ''}
									>
										<Input
											id="Last 4 digits of your Social Security Number"
											isClearable={true}
											onClear={() => {
												setValue('ssn', '');
											}}
											isIncomplete={error ? true : false}
											isTypePassword={true}
											onchange={evt => {
												if (!/^[0-9]*$/.test(evt.target.value) || evt.target.value.length > 4) {
													evt.preventDefault();
												} else {
													onChange(evt);
												}
											}}
											onChangeType={() => {
												setPasswordEye(!isPasswordEyeOpen);
											}}
											type={isPasswordEyeOpen ? InputTypes.TEXT : InputTypes.PASSWORD}
											value={value}
											autoComplete="off"
											name="signup_ssn"
										/>
									</FormControl>
								</>
							)}
						/>
					</div>

					<div>
						<Controller
							control={control}
							name="dob"
							rules={{
								required: 'Error: Date of Birth is required.'
							}}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<>
									<FormControl
										controlType={ControlType.LIGHT}
										label={() => 'Date of Birth'}
										error={() => error?.message || ''}
									>
										<DatePicker
											id="Date of Birth"
											className="zenda-datepicker"
											showPopperArrow={false}
											selected={value ? new Date(value) : null}
											onChange={onChange}
											dropdownMode="select"
											showMonthDropdown
											showYearDropdown
											adjustDateOnChange
											nextMonthAriaLabel={
												value
													? `Month changed to: ${new Date(value).toLocaleString('default', {
															month: 'long',
															year: 'numeric'
													  })}`
													: ''
											}
											previousMonthAriaLabel={
												value
													? `Month changed to: ${new Date(value).toLocaleString('default', {
															month: 'long',
															year: 'numeric'
													  })}`
													: ''
											}
										/>
									</FormControl>
								</>
							)}
						/>
					</div>

					<div>
						<Controller
							control={control}
							name="email"
							rules={{
								required: 'Error: Email is required.',
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: 'Error: Please enter valid email.'
								}
							}}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<>
									<FormControl
										controlType={ControlType.LIGHT}
										label={() => 'Work Email'}
										error={() => error?.message}
									>
										<Input
											id="Work Email"
											onchange={onChange}
											onClear={() => {
												setValue('email', '');
											}}
											reference={emptyDivRef}
											isIncomplete={error ? true : false}
											isClearable={true}
											type={InputTypes.EMAIL}
											value={value}
											autoComplete="off"
											name="signup_email"
										/>
									</FormControl>
								</>
							)}
						/>
					</div>
					<div className={`d-flex flex-column gap-16 mt-32 py-16 px-0`}>
						<Button
							onClick={() => {
								emptyDivRef?.current?.focus();
							}}
							type={ButtonTypes.TERTIARY}
						>
							Submit
						</Button>
						<Button
							type={ButtonTypes.PRIMARY}
							onClick={e => {
								e.preventDefault();
								navigate('/login');
							}}
						>
							Back
						</Button>
					</div>
				</form>
				{/* <StyledLink href="/login" isOpenInNewTab={false}>
					I already have a Zenda account
				</StyledLink> */}
				<p className="text-base-325 p-16">
					<span className={classes['lost-text']}>Lost access to email?</span>
					<StyledLink href="mailto:support@incommbenefits.com" type={StyledLinkTypes.TERTIARY}>
						Contact Us
					</StyledLink>
				</p>
			</div>
		</div>
	);
};
